import React, { useEffect, useState } from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import { fieldToArray } from '../UI/functions/functions';
import { updateDataKey } from '../UI/functions/updateDataKey/updateDataKey';
import FormBlock from '../FormBlock/FormBlock';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import ModalMap from '../ModalMap/ModalMap';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataMicrodist, clearDataMicrodistKey, setDataPressItem } from '../../store/microdistDataReduces';
import LandPlot from '../../api/Landplot/LandPlot';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ImageThumbnail from '../UI/imageThumbnail/imageThumbnail';
import FilePickerModal from '../UI/FilePickerModal/FilePickerModal';
import FilePicker from '../UI/FilePicker/FilePicker';
import Checkbox from '../Checkbox/Checkbox';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import { setLoading } from '../../store/loadingReducer';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import MapComponents from '../MapComponents/MapComponents';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import StatusesApiRequest from '../../api/Statuses/Statuses';
import groupUser from '../../locales/groupUser';
import Skeleton from 'react-loading-skeleton';
import DefaultMapFilter from '../DefaultMapFilter/DefaultMapFilter';
import MapComponent from '../MapComponent/MapComponent';
import Button from '../Buttons/Button';
import { ReactComponent as Pen } from '../../assets/icons/Pencil.svg'

const LandploatForm = ({ data, options, type }) => {


    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const GeoInventarisationChoice = userData?.user?.groups?.some(group => group.name === groupUser.GeoInventarisationChoice);


    const landPlot = new LandPlot();
    const statusesApi = new StatusesApiRequest();
    const dispatch = useDispatch();
    const [dataOption, setOptions] = useState([]);
    const [optionsSubStatus, setOptionsSubStatus] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isRegion, setIsRegion] = useState('');
    const [isCoordinatsMicroDistrict, setIsCoordinatsMicroDistrict] = useState([]);
    const [isActiveLandploat, setIsActiiveLandploat] = useState();
    const [districtData, setDistrictData] = useState();
    const [isErr, setIsErr] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const needsRerender = useSelector((state) => state.needsRerender);
    const microdistData = useSelector((state) => state.mocrodist_data);
    const loading = useSelector(state => state.loading);
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        dispatch(setLoading(true));
        setOptions(options)
        dispatch(setLoading(false));
    }, [options]);

    useEffect(() => {
        dispatch(setLoading(true));
        const dataField = fieldToArray(data);

        dataField?.forEach((item) => {
            if (item.key === 'system_status') {
                item?.value?.map((choise) => {
                    handleSystemStatusChange(choise)
                    dispatch(setLoading(false));
                })
            } else {
                dispatch(setDataPressItem(['inform_tab', { ...microdistData.inform_tab, [item.key]: item.value }]));
                dispatch(setLoading(false));
            }

        });
    }, [dispatch]);

    // useEffect(() => {
    //     let url = id ? microdistData.inform_tab.micro_district : microdistData.micro_district
    //     if (url != undefined) {
    //         micropdistPlotInstance.mapApi(url, '?ignore_filters')
    //             .then((resp) => {
    //                 if (resp.success) {
    //                     setDistrictData(resp.data.land_plots)
    //                     setIsCoordinatsMicroDistrict(resp.data.coordinates);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Ошибка при получении данных:', error);
    //             });

    //     }
    // }, [])

    const setMapData = (data) => {
        setDistrictData(data.land_plots)
        setIsCoordinatsMicroDistrict(data.coordinates);
    }

    useEffect(() => {
        if (id === undefined) {
            dataOption?.map((item) => {
                if (item.key === 'system_status') {
                    item.value.choices.map((dataChoice) => {
                        handleSystemStatusChange(dataChoice.value)
                    })
                }
            })
        }
    }, [dataOption])

    const [selectedSystemStatus, setSelectedSystemStatus] = useState([]);


    const handleSystemStatusChange = (itemValue) => {
        if (selectedSystemStatus.includes(itemValue)) {
            setSelectedSystemStatus((prevSelected) =>
                prevSelected.filter((value) => value !== itemValue)
            );
        } else {
            setSelectedSystemStatus((prevSelected) => [...prevSelected, itemValue]);
        }
    };

    useEffect(() => {
        handleChangeInput('system_status', selectedSystemStatus)
    }, [selectedSystemStatus])

    const dataFields = {
        gas: ['gas_supply_building_status', 'gas_supply_date_end'],
        water: ['water_supply_building_status', 'water_supply_date_end', 'sanitation', 'property'],
        disposal: ['water_disposal_building_status', 'water_disposal_date_end', 'water_disposal_property'],
        road: ['road_surface_building_status', 'road_surface_date_end', 'road_surface_type'],
        power: ['power_supply_building_status', 'power_supply_date_end'],
        social: ['social_object'],
        commercial: ['commercial_object'],
        emergency: ['emergency_service'],
    };

    const renderedKeys = [
        ...dataFields.gas,
        ...dataFields.water,
        ...dataFields.disposal,
        ...dataFields.road,
        ...dataFields.power,
        ...dataFields.social,
        ...dataFields.commercial,
        ...dataFields.emergency,
    ];



    const classMappings = {
        'gas_supply_building_status': 'col-1',
        'power_supply_building_status': 'col-1',
        'water_supply_building_status': 'col-1',
        'water_disposal_building_status': 'col-1',
        'road_surface_building_status': 'col-1',
        'cadastral': 'col-1',
        'gas_supply_date_end': 'col-2',
        'power_supply_date_end': 'col-2',
        'water_supply_date_end': 'col-2',
        'water_disposal_date_end': 'col-2',
        'road_surface_date_end': 'col-2',
        'commercial_object': 'col-3',
        'social_object': 'col-3',
    };

    const classContain = {
        'cadastral': 'col-1',
        'type': 'col-1',
        'land_cost': 'col-1',
        'market_value': 'col-1',
        'number': 'col-2',
        'area': 'col-2',
        'utilities_cost': 'col-2',
        'down_payment': 'col-2',
    };

    useEffect(() => {
        dataOption && setIsRegion(dataOption?.filter((item) => item.key === 'municipality')
            .map((item) => {
                return item.value.choices.find((data) =>
                    id ? microdistData.inform_tab.municipality === data.value ? data.display_name : ''
                        :
                        microdistData.municipality === data.value ? data.display_name : ''
                );
            })
        )

    }, [microdistData, dataOption])

    const handleChangeInput = (field, value) => {
        if (!id) {
            dispatch(setDataPressItem([field, value]));
        } else {
            dispatch(setDataPressItem(['inform_tab', { ...microdistData.inform_tab, [field]: value }]));
        }
    };


    const handleToggleField = (fieldName, isChecked) => {
        if (!id) {
            dispatch(setDataPressItem([fieldName, isChecked]));
        } else {
            dispatch(setDataPressItem(['inform_tab', { ...microdistData.inform_tab, [fieldName]: isChecked }]));
        }


    };

    const saveDisctrict = () => {
        // dispatch(setLoading(true));
        let hasError = false;

        id != undefined ?
            dataOption.inform_tab.forEach((item) => {
                if (item.value.required && (!microdistData.inform_tab[item.key] || microdistData.inform_tab[item.key].length === 0)) {
                    hasError = true;
                }
            })
            :
            fieldToArray(dataOption.inform_tab).forEach((item) => {
                if (item.value.required && (!microdistData[item.key] || microdistData[item.key].length === 0)) {
                    hasError = true;
                }
            });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
            dispatch(setLoading(false));
        } else {
            landPlot.create({ body: id ? microdistData : microdistData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    setIsFormSubmitted(true);
                    dispatch(setLoading(false));
                    history.push(`/landPLots`)
                }

            }).finally((item) => {
                dispatch(setLoading(false));
            })
        }
    }

    const updateDisctrict = () => {
        // dispatch(setLoading(true));
        let hasError = false;
        const errors = []
        dispatch(setDataPressItem(['tab', 'inform_tab']));
        id ?
            dataOption.forEach((item) => {
                if (item.value.required && (!microdistData.inform_tab[item.key] || microdistData.inform_tab[item.key].length === 0)) {
                    hasError = true;
                    errors.push(item.value.label === 'Coordinates' ? 'Укажите координаты' : item.value.label)
                }
            })
            :
            dataOption.forEach((item) => {
                if (item.value.required && (!microdistData[item.key] || microdistData[item.key].length === 0)) {
                    hasError = true;
                    errors.push(item.value.label === 'Coordinates' ? 'Укажите координаты' : item.value.label)
                }
            });

        if (hasError) {
            setIsErr(`Заполните все обязательные поля${errors.length > 0 ? ': ' + errors.join(', ') : '.'}`);
            dispatch(setLoading(false));
        } else {
            landPlot.update({ urlParams: microdistData.inform_tab.id ? microdistData.inform_tab.id + '/' : microdistData.id + '/', body: id ? microdistData : microdistData }).then((resp) => {
                if (resp.success) {
                    setIsComplete('Данные отправлены')
                    dispatch(setLoading(false));
                }
                dispatch(setNeedsRerender(!needsRerender.needsRerender));

            }).finally((item) => {
                dispatch(setLoading(false));
            })
        }
    }
    const delteDisctrict = () => {
        landPlot.delete({ urlParams: id + '/' }).then((resp) => {
            history.push(`/landPLots`)
        })
    }

    useEffect(() => {
        dispatch(clearDataMicrodistKey(microdistData?.inform_tab?.sub_status))
        if (id ? microdistData.inform_tab['status'] !== undefined : microdistData['status'] !== undefined) {
            statusesApi.listSubStatuses((id ? microdistData.inform_tab['status'] : microdistData['status']) + '/').then((resp) => {
                if (resp.success) {
                    if (resp.data.length !== 0) {
                        setOptionsSubStatus(resp.data)
                        dispatch(setDataPressItem(['inform_tab', { ...microdistData.inform_tab, sub_status: null }]));
                    } else {
                        setOptionsSubStatus([])
                        dispatch(setDataPressItem(['inform_tab', { ...microdistData.inform_tab, sub_status: null }]));

                    }

                }
            })
        }
    }, [id ? microdistData?.inform_tab['status'] : microdistData['status']])
    // }, [])

    useEffect(() => {
        if (id === undefined) {
            dispatch(clearDataMicrodist());
        }
    }, [id])

    return (

        <>
            {
                isErr &&
                <ErrorMessage
                    message={isErr}
                    onClose={() => setIsErr(false)}
                    type={'error'}
                    onClick={() => {
                        id == undefined ? saveDisctrict() : updateDisctrict();
                    }}
                />
            }
            {
                isComplete &&
                <ErrorMessage
                    message={isComplete}
                    onClose={() => setIsComplete(false)}
                    type={'success'}
                />
            }
            {openModal && (id ? microdistData.inform_tab : microdistData) &&
                <ModalMap
                    onClose={() => setOpenModal(false)}
                    titleHeader={id ? microdistData.inform_tab.name : microdistData.name}
                    subTitleHeader={id ? microdistData.inform_tab.municipality : microdistData.municipality}
                    regionData={id ? microdistData.inform_tab.micro_district : microdistData.micro_district}
                    activeLandploat={id && microdistData.inform_tab}
                />
            }
            <div className='continerLandplotPage'>
                <div className='inputContainerCol'>
                    {dataOption && dataOption?.map((item, index) => {
                        const key = item.key;
                        const value = item.value;
                        const isSpecialField = [
                            'is_gas_supply',
                            'is_water_supply',
                            'is_water_disposal',
                            'is_road_surface',
                            'is_power_supply',
                            'is_social_objects',
                            'is_commercial_objects',
                            'emergency_service',
                        ].includes(key);
                        const emergencyDataResults =
                            key === 'emergency_service' && fieldToArray(value.child.children);

                        if (key === 'id' || key === 'coordinates' || key === 'municipality' || key === 'tab' || renderedKeys.includes(key)) {
                            return null;
                        }
                        if (key === 'sub_status') {
                            if ((id ? microdistData.inform_tab['status'] === '' || microdistData.inform_tab['status'] === undefined : microdistData['status'] === '' || microdistData['status'] === undefined) || optionsSubStatus?.length === 0) {
                                return null;
                            }
                        }

                        renderedKeys.push(key);

                        return (
                            <div className={classContain[key] || 'col-3'} key={index}>

                                {isSpecialField ? (

                                    <div className={classContain[item.key] || 'fromBlockGray col-3'} key={index}>
                                        <FormInput
                                            subInput={item.value.label}
                                            type={item.value.type}
                                            options={item.value.choices}
                                            onChange={handleToggleField}
                                            classesTextToggle={'textToggle'}
                                            keyData={item.key}
                                            required={item.value.required}
                                            value={id ? microdistData.inform_tab[item.key] : microdistData[item.key]}
                                            checked={id ? microdistData.inform_tab[item.key] : microdistData[item.key]}
                                            loading={loading}
                                            error={isFormSubmitted}
                                        />
                                        <div className={`contentBlockGray ${(id ? microdistData.inform_tab[item.key] : microdistData[item.key]) ? '' : 'visibleBlock'}`} key={item.key}>
                                            {dataOption?.filter((itemData) => {
                                                const fieldData = dataFields[key.split('_')[2] === 'disposal' ? key.split('_')[2] : key.split('_')[1]];

                                                return fieldData && fieldData.includes(itemData.key);
                                            })
                                                .map((itemData, subIndex) => {
                                                    const commercialDataResults =
                                                        dataOption?.filter((itemData) => itemData.key == 'commercial_object')
                                                            .reduce((acc, itemData) => {
                                                                const commercialFields = fieldToArray(itemData.value.child.children);
                                                                return [...acc, ...commercialFields];
                                                            }, []);

                                                    const socialDataResults =
                                                        dataOption?.filter((itemData) => itemData.key == 'social_object')
                                                            .reduce((acc, itemData) => {
                                                                const socialFields = fieldToArray(itemData.value.child.children);
                                                                return [...acc, ...socialFields];
                                                            }, []);
                                                    return (
                                                        <div className={classMappings[itemData.key] || 'col-3'} key={subIndex}>
                                                            {itemData.key !== 'commercial_object' && itemData.key !== 'social_object' ? (

                                                                <FormInput
                                                                    subInput={itemData.value.label}
                                                                    type={itemData.value.type}
                                                                    options={itemData.value.choices}
                                                                    keyData={itemData.key}
                                                                    onChange={(e) => handleChangeInput(itemData.key, e)}
                                                                    required={itemData.value.required}
                                                                    value={id ? microdistData.inform_tab[itemData.key] : microdistData[itemData.key]}
                                                                    checked={id ? microdistData.inform_tab[itemData.key] : microdistData[itemData.key]}
                                                                    loading={loading}
                                                                    error={isFormSubmitted}
                                                                />

                                                            ) : itemData.key === 'commercial_object' ? (
                                                                <FormBlock
                                                                    title={`Коммерческий объект`}
                                                                    dataOptions={commercialDataResults}
                                                                    onChange={(e) => handleChangeInput('commercial_object', e)}
                                                                    initData={id ? microdistData.inform_tab.commercial_object : microdistData.commercial_object}
                                                                    textAddButton={'Добавить объект'}
                                                                />
                                                            ) : (
                                                                <FormBlock
                                                                    title={`Социальный объект`}
                                                                    dataOptions={socialDataResults}
                                                                    onChange={(e) => handleChangeInput('social_object', e)}
                                                                    initData={id ? microdistData.inform_tab.social_object : microdistData.social_object}
                                                                    textAddButton={'Добавить объект'}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>

                                ) : (
                                    (() => {
                                        switch (item.key) {
                                            case "images":
                                                return (
                                                    <>
                                                        <h1>{item.value.label}</h1>
                                                        <ImageThumbnail
                                                            imageData={!id ? microdistData[item.key] : microdistData.inform_tab[item.key]}
                                                            upload={id ? microdistData.inform_tab[item.key] : false}
                                                            modal={true}
                                                        />
                                                        <FilePicker
                                                            type={'image'}
                                                            dataFile={!id ? microdistData[item.key] : microdistData.inform_tab[item.key]}
                                                            setFiles={(e) => { handleChangeInput('images', e) }}
                                                        />
                                                    </>
                                                );

                                            default:
                                                return (
                                                    <div className={classContain[item.key] || 'col-3'}>
                                                        {
                                                            item.key === "system_status" ?
                                                                <>
                                                                    <h1 className='microTitle'>Статус в системе</h1>
                                                                    {value.choices.map((item) => {
                                                                        const isChecked = id ? microdistData?.inform_tab?.system_status?.includes(item.value) : microdistData?.system_status?.includes(item.value);
                                                                        return (
                                                                            <Checkbox
                                                                                key={item.value}
                                                                                id={item.value}
                                                                                label={item.display_name}
                                                                                checked={isChecked}
                                                                                onChange={() => handleSystemStatusChange(item.value)}
                                                                            />
                                                                        )
                                                                    })}

                                                                </>
                                                                :
                                                                <>
                                                                    <FormInput
                                                                        subInput={item.value.label}
                                                                        type={item.key === 'placement' ? 'radio' : item.value.type}
                                                                        options={item.key === 'sub_status' ?
                                                                            (!isAdmin && !GeoInventarisationChoice)
                                                                            ? optionsSubStatus.filter(data => data.value !== 13 && data.display_name !== "Инвентаризация")
                                                                            : optionsSubStatus : item.value.choices}
                                                                        keyData={item.key}
                                                                        onChange={(e) =>
                                                                            item.key === 'is_control_department' ?
                                                                                handleToggleField(item.key, !(id ? microdistData.inform_tab[item.key] : microdistData[item.key]))
                                                                                :
                                                                                handleChangeInput(item.key, e)}
                                                                        required={item.value.required}
                                                                        key={index}
                                                                        value={id ? microdistData.inform_tab[item.key] : microdistData[item.key]}
                                                                        mask={item.key === 'cadastral' && '99:99:9999999:9999'}
                                                                        loading={loading}
                                                                        error={isFormSubmitted}
                                                                        checked={(id ? microdistData.inform_tab[item.key] : microdistData[item.key])}
                                                                    />

                                                                    {(item.key === 'micro_district' && (id ? microdistData.inform_tab[item.key] : microdistData[item.key])) && (
                                                                        <Button
                                                                            title={microdistData.inform_tab?.coordinates?.length > 0 ? 'Изменить координаты' : 'Добавить координаты'}
                                                                            Icon={Pen}
                                                                            className={` _block _sm mt-16 ${microdistData.inform_tab?.coordinates?.length > 0 ? 'btn-secondary' : 'btn _red'}`}
                                                                            onClick={() => {
                                                                                setOpenModal(true)
                                                                            }} />
                                                                    )}
                                                                </>
                                                        }

                                                    </div>
                                                );
                                        }
                                    })()
                                )}
                            </div>
                        );
                    })}
                    {id != undefined && isAdmin &&
                        <Buttons
                            text={'Удалить участок'}
                            className={'deleteButton col-3'}
                            onClick={() => { delteDisctrict() }}
                        />
                    }
                </div>
                <div className='mapContainerLandploat'>
                    {(districtData) ? (
                        <>
                            {/* <MapComponents
                                coordinats={
                                    isCoordinatsMicroDistrict ? isCoordinatsMicroDistrict : []
                                }
                                styleContainer={"styleMapContainer"}
                                mapStyle={"mapStyle"}
                                modal={false}
                                onClose={() => { }}
                                onChange={() => { }}
                                landplot={districtData}
                                isClick={false}
                                activeLandploat={microdistData?.inform_tab && microdistData?.inform_tab}
                                optionsMap={undefined}
                            /> */}
                            <MapComponent
                                district={
                                    isCoordinatsMicroDistrict ? isCoordinatsMicroDistrict : []
                                }
                                landplots={districtData}
                                isClick={false}
                                classes={'MapComponent _form'}
                                activeLandplot={microdistData?.inform_tab && microdistData?.inform_tab}
                            />
                        </>
                    ) : (
                        id ? <p>Загрузка карты</p> : <></>
                    )}
                    <DefaultMapFilter setMapData={setMapData} />
                </div>
                    <Buttons text={'Сохранить'} className={'saveButton'} ico={icons.floppyCheckmark} onClick={() => { id == undefined ? saveDisctrict() : updateDisctrict() }} />
            </div>
        </>
    );
};



export default LandploatForm;
