import React, { useCallback, useEffect, useState } from 'react'
import apiConfig from '../../api/apiConfig';
import { formatDateIntlDate } from '../UI/functions/functions';
import icons from '../../assets/icons/icons';
import { useHistory, useParams } from 'react-router-dom';
import MicroDistrict from '../../api/microDistrict/MicroDistrict';
import ConstructionReportApiRequest from '../../api/ConstructionReport/ConstructionReport';
import GeoObjectReportApiRequest from '../../api/geoobjectReport/geoobjectReport';
import LandPlot from '../../api/Landplot/LandPlot';
import TableInputCell from './TableInputCell';
import PaymentApiRequest from '../../api/Payment/Payment';
import { useDispatch, useSelector } from 'react-redux';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import groupUser from '../../locales/groupUser';

const TableRow = ({ isList, item, options, type, paymentData, isCreate = false, closeCreate = null }) => {
    const microdistrict = new MicroDistrict()
        , landPlot = new LandPlot()
        , geoObjectApi = new GeoObjectReportApiRequest()
        , paymentApi = new PaymentApiRequest()
        , history = useHistory()
        , constructionReport = new ConstructionReportApiRequest()
        , needsRerender = useSelector((state) => state.needsRerender)
        , userData = useSelector(state => state.user_data.userData)
        , isAdmin = userData.user.groups?.some(group => group.name === groupUser.admin)
        , dispatch = useDispatch()
        , [isEdit, setIsEdit] = useState(isCreate)
    const [paymentFormData, setPaymentFormData] = useState({ ...item, plot_payment_field: options?.key })

    const formOpen = (id, e, data) => {
        e.preventDefault()
        switch (type) {
            case 'microdistrict':
                microdistrict.getById({ urlParams: id }).then((resp) => {
                    if (resp.success) {
                        history.push(`/microdistrict/${id}`, resp.data);
                    }
                });
                break;
            case 'landplot':
                landPlot.getById({ urlParams: id }).then((resp) => {
                    if (resp.success) {
                        history.push(`/landplot/${id}`, resp.data);
                    }
                });
                break;
            case 'control':
                landPlot.getById({ urlParams: id }).then((resp) => {
                    if (resp.success) {
                        history.push(`/control/${id}`, resp.data);
                    }
                });
                break;
            case 'geoobject':
                geoObjectApi.getById({ urlParams: id }).then((resp) => {
                    if (resp.success) {
                        history.push(`/geoobject/${id}`, resp.data);
                    }
                });
                break;
            case 'clients':
                landPlot.getById({ urlParams: id }).then((resp) => {
                    if (resp.success) {
                        history.push(`/landplot/${id}`, resp.data);
                    }
                });
                break;
            default:
                break;
        }
    }

    const regenerateFile = (id, data) => {
        constructionReport.regenerateFile(id, data).then((resp) => {
            if (resp.success) {

                window.location.reload();
            }
        });
    }

    const openEditPayment = () => {
        setIsEdit(true)
    }

    const editPayment = useCallback(() => {
        let payment = { ...paymentFormData }

        payment[paymentData.identifier] = paymentData.id
        if (isCreate) {
            paymentApi.create({ body: JSON.stringify(payment) })
                .then(response => {
                    if (response.success) {
                        setIsEdit(false)
                        closeCreate()
                        dispatch(setNeedsRerender(!needsRerender.needsRerender))
                    } else {
                        console.error(response)
                    }
                })
        } else {
            paymentApi.update({ id: item.id + '/', body: JSON.stringify(payment) })
                .then(response => {
                    if (response.success) {
                        setIsEdit(false)
                        dispatch(setNeedsRerender(!needsRerender.needsRerender))
                    } else {
                        console.error(response)
                    }
                })
        }

    }, [paymentFormData, paymentData])

    const deletePayment = () => {
        if (window.confirm("Вы действительно хотите безвозвратно УДАЛИТЬ платеж?")) {
            paymentApi.delete({ id: item.id + '/' })
                .then(response => {
                    if (response.success) {
                        dispatch(setNeedsRerender(!needsRerender.needsRerender))
                    } else {
                        console.error(response)
                    }
                })
        }
    }

    const setData = (key, value) => {
        let payment = { ...paymentFormData }
        payment[key] = value

        setPaymentFormData({ ...payment })
    }


    return (
        <tr className={`tablRow ${item?.payment_type === 'фактический' ? '_fact' : item?.payment_type === 'плановый' ? '_plan' : '' }`}>
            {isList?.map((listItem) => {

                if (listItem.key !== 'id' && listItem.key !== "landplot_id" && listItem.key !== 'report_status') {

                    const key = listItem.key;
                    const value = key.endsWith('date') ? item[key] ? formatDateIntlDate(item[key]) : '' :
                        key === 'constructions_stage' || key === 'contract_status' || key === 'contract_type' ?
                            listItem?.value?.style?.choices.map((dataStage) => {
                                if (dataStage[0] === item[key])
                                    return (
                                        dataStage[1]
                                    )
                            })
                            :
                            item[key] || '';




                    const valueSubStatus = item.status || '';
                    const cellClassName = (typeof value === 'number' || value == 0) ? 'alignRight' : '';
                    return (
                        <td
                            key={key}
                            className={
                                key === 'type' || key === 'contragent' || key === 'municipality' ? 'mini' :
                                    (() => {
                                        switch (key === 'sub_status' ? valueSubStatus : value) {
                                            case 'Подтвержденная бронь':
                                                return 'cellOrangeTd';
                                            case 'Передано в аренду':
                                                return 'cellOrangeTd';
                                            case 'Первичная бронь':
                                                return 'cellYelloTd';
                                            case 'Аренда':
                                                return 'cellYelloTd';
                                            case 'В продаже':
                                                return 'cellGreenTd';
                                            case 'Собственность':
                                                return 'cellGreenTd';
                                            case 'В работе':
                                                return 'cellBlueTd';
                                            case 'Реализовано':
                                                return 'cellGreyTd';
                                            case 'Не в нашей собственности':
                                                return 'cellGreyTd';
                                            // case 'плановый':
                                            //     return '_plan';
                                            // case 'фактический':
                                            //     return '_fact';
                                            default:
                                                return cellClassName;
                                        }
                                    })()
                            }>
                            {
                                key === 'file' ?
                                    (() => {
                                        switch (item.report_status) {
                                            case 'COMPLETE':
                                                return (
                                                    <a className='downloadReport' href={`${apiConfig.baseUrlMedia}media/${item.file}`} download={`${apiConfig.baseUrlMedia}media/${item.file}`}><img src={icons.download}></img>Скачать отчет</a>
                                                );
                                            case 'CREATE_FILE':
                                                return (
                                                    <a className='reportCreatingText'><img src={icons.clockTime}></img> Отчет формируется</a>
                                                );
                                            default:
                                                return (<a className='reportErrorText' onClick={(e) => { regenerateFile(item.id, item) }}>Сформировать повторно</a>)
                                        }
                                    })()
                                    : isEdit ?
                                        <TableInputCell defaultValue={paymentFormData[key]} onChange={(name, e) => setData(name, e)} listItem={listItem} />

                                        :
                                        <a title={listItem.value.label} onClick={(e) => { formOpen(type === 'clients' ? item.landplot_id : item.id, e) }} >
                                            {value === '' ? '-' : value}
                                        </a>
                            }
                        </td>


                    );
                }
                return null;
            })}
            {type === 'payment_history' && <td><div className='tablControlPanel'>
                {isEdit ? <><span className='TableIcon _green' onClick={() => editPayment()}><img src={icons.TableCheck} alt="" /></span>
                    <span className='TableIcon _blue' onClick={() => {
                        if (closeCreate) {
                            closeCreate()
                        } else {
                            setIsEdit(false)
                        }
                    }}><img src={icons.TableCross} alt="" /></span></>
                    : <><span className='TableIcon _blue' onClick={openEditPayment}><img src={icons.TablePencil} alt="" /></span>
                        <span className='TableIcon _red' onClick={deletePayment}><img src={icons.TableTrash} alt="" /></span></>}
            </div></td>}

        </tr >
    )
}

export default TableRow