import React, {useEffect, useMemo, useState} from 'react';
import './styles.scss';
import icons from '../../assets/icons/icons';
import Tables from '../Tables/Tables';
import {useSelector} from 'react-redux';

const RightSidebar = ({openRightBar, onClosed, isList, barData}) => {
    const [emptyPayment, setEmptyPayment] = useState(false)
    const [planTotal, setPlanTotal] = useState(0);
    const [factTotal, setFactTotal] = useState(0);

    const microdistData = useSelector((state) => state.mocrodist_data)
        , isData = useMemo(() => {
        if (barData?.matchingKey && barData?.key) {
            const tableData = microdistData.payment_tab[barData.matchingKey]?.[barData.key]
            if (tableData)
                return tableData
        }
        return []
    }, [{...microdistData}, {...barData}])

    const addEmptyPayment = () => {
        setEmptyPayment(true)
    }

    const closeCreate = () => {
        setEmptyPayment(false)
    }

    useEffect(() => {
        let plan = 0;
        let fact = 0;

        for (let item of isData) {
            if (item.payment_type === 'плановый') {
                plan += +item.payment_amount;
            } else if (item.payment_type === 'фактический') {
                fact += +item.payment_amount;
            }
        }

        setFactTotal(fact.toLocaleString('ru-RU'));
        setPlanTotal(plan.toLocaleString('ru-RU'));

    }, [isData]);


    return (
        <div className={`rightSidbarContainer ${openRightBar && 'activeBar'}`}>
            <div className='sidebarHeader'>
                <div className='sidebarClosedButton' onClick={() => {
                    onClosed()
                }}>
                    <img src={icons.ArrowBack}></img>
                    <p>Закрыть</p>
                </div>
                <button className='sidebarAddPaymentButton' onClick={addEmptyPayment}>Добавить платеж</button>
            </div>
            <p className='sidebarTitle'>{barData?.title}</p>
            <Tables isList={isList} isData={isData} options={barData?.options} paymentData={barData?.paymentData}
                    emptyPayment={emptyPayment} type={'payment_history'} closeCreate={closeCreate}/>

            <p className='planTotal'>Всего планово: {planTotal}</p>
            <p className='factTotal'>Всего фактически: {factTotal}</p>
        </div>
    )
}

export default RightSidebar